import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  Input,
  Modal,
  Text,
  Image,
} from "@nextui-org/react";
import { useAuth } from "../context/AuthContext";
import { useRouter } from "next/router";
import { AuthProvider } from "../context/AuthContext";
import { auth, db, analytics } from "../config/firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import * as Sentry from "@sentry/browser";

import { setDoc, doc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [signUpConfirmPassword, setSignUpConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [requiredFieldWarning, setRequiredFieldWarning] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const closeHandler = () => {
    setVisible(false);
    //console.log("closed");
  };

  const { currentUser } = useAuth();
  const auth = getAuth();

  const router = useRouter();

  function login() {
    //e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        logEvent(analytics, "login");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
        setVisible(true);
      });
  }

  async function signup() {
    //e.preventDefault();

    // make sure all fields are filled in
    if (
      signUpEmail == "" ||
      !signUpEmail ||
      signUpPassword == "" ||
      !signUpPassword ||
      signUpConfirmPassword == "" ||
      name == "" ||
      businessName == "" ||
      businessDescription == "" ||
      businessLocation == ""
    ) {
      setRequiredFieldWarning(true);
      return;
    } else {
      // console.log(signUpEmail);
      // console.log(signUpPassword);
      // console.log(signUpConfirmPassword);
      // console.log(name);
      // console.log(businessName);
      // console.log(businessDescription);
      // console.log(businessLocation);
    }

    //  make sure passwords match
    if (signUpPassword != signUpConfirmPassword) {
      setPasswordError(true);
      return;
    }

    createUserWithEmailAndPassword(auth, signUpEmail, signUpPassword)
      .then((userCredential) => {
        // Signed in
        const email = userCredential.user.email;

        const docRef = setDoc(doc(db, "Users", email), {
          FirstName: name,
          BusinessName: businessName,
          BusinessLocation: businessLocation,
          BusinessDescription: businessDescription,
        });

        setSignUpVisible(false);

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        Sentry.captureException(error);
        setError(errorMessage);
        setVisible(true);
      });
  }

  return (
    <>
      {/* <NavBarComponent /> */}
      <Container
        alignContent="center"
        justify="center"
        alignItems="center"
        aria-label="container"
        // fluid
      >
        <Grid.Container
          gap={2}
          alignContent="center"
          justify="center"
          alignItems="center"
          aria-label="grid-container"
          // fluid
          style={{ marginTop: 150 }}
        >
          <Card css={{ mw: "400px" }}>
            <Card.Body>
              <Image
                width={320}
                height={180}
                src="/logo.png"
                alt="Default Image"
                objectFit="cover"
                aria-label="image"
              />
              <Grid.Container gap={2} justify="center">
                <Grid xs={12}>
                  <Input
                    clearable
                    bordered
                    color="primary"
                    placeholder="Email"
                    size="lg"
                    value={email}
                    fullWidth={true}
                    aria-label="email"
                    onChange={(e) => setEmail(e.target.value)}
                    contentLeft={<Mail fill="currentColor" />}
                  />
                </Grid>
                <Grid xs={12}>
                  <Input.Password
                    clearable
                    bordered
                    color="primary"
                    size="lg"
                    fullWidth={true}
                    placeholder="Password"
                    value={password}
                    aria-label="password"
                    onChange={(e) => setPassword(e.target.value)}
                    contentLeft={<Password fill="currentColor" />}
                  />
                </Grid>
                {/* <Row justify="space-between">
                    <Checkbox>
                      <Text size={14}>Remember me</Text>
                    </Checkbox>
                    <Text size={14}>Forgot password?</Text>
                  </Row> */}
                <Button onPress={() => login()}>Sign in</Button>

                <Button
                  onPress={() => setSignUpVisible(true)}
                  style={{ marginTop: 10 }}
                >
                  Sign Up
                </Button>
              </Grid.Container>
            </Card.Body>
          </Card>
        </Grid.Container>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={visible}
          onClose={closeHandler}
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Error
            </Text>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button auto flat color="error" onPress={closeHandler}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          //closeButton
          preventClose
          blur
          aria-labelledby="modal-title"
          open={signUpVisible}
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Sign Up
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Input
              clearable
              bordered
              color="primary"
              size="lg"
              value={signUpEmail}
              label="Email Address"
              onChange={(e) => setSignUpEmail(e.target.value)}
            />
            <Input.Password
              clearable
              bordered
              color="primary"
              size="lg"
              value={setSignUpPassword}
              label="Password"
              onChange={(e) => setSignUpPassword(e.target.value)}
            />
            <Input.Password
              clearable
              bordered
              color="primary"
              size="lg"
              value={signUpConfirmPassword}
              label="Confirm Password"
              onChange={(e) => setSignUpConfirmPassword(e.target.value)}
            />
            <Input
              clearable
              bordered
              color="primary"
              size="lg"
              value={name}
              label="Your Name"
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              clearable
              bordered
              color="primary"
              size="lg"
              value={businessName}
              label="Business Name"
              onChange={(e) => setBusinessName(e.target.value)}
            />

            <Input
              clearable
              bordered
              color="primary"
              size="lg"
              value={businessLocation}
              label="Business Location"
              onChange={(e) => setBusinessLocation(e.target.value)}
            />
            <Input
              clearable
              bordered
              color="primary"
              size="lg"
              value={businessDescription}
              label="Short description of your business"
              onChange={(e) => setBusinessDescription(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button auto color="error" onPress={() => setSignUpVisible(false)}>
              Close
            </Button>
            <Button auto onPress={() => signup()}>
              Create Account
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={requiredFieldWarning}
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Error
            </Text>
          </Modal.Header>
          <Modal.Body>Please fill out all fields</Modal.Body>
          <Modal.Footer>
            <Button
              auto
              flat
              color="error"
              onPress={() => setRequiredFieldWarning(false)}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal closeButton aria-labelledby="modal-title" open={passwordError}>
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Error
            </Text>
          </Modal.Header>
          <Modal.Body>Passwords must match</Modal.Body>
          <Modal.Footer>
            <Button
              auto
              flat
              color="error"
              onPress={() => setPasswordError(false)}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

const Mail = ({ fill, size, height, width, ...props }) => {
  return (
    <svg
      width={size || width || 24}
      height={size || height || 24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M12 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v3" />
        <path d="M17 9l-3.13 2.5a3.166 3.166 0 01-3.75 0L7 9M19.21 14.77l-3.539 3.54a1.232 1.232 0 00-.3.59l-.19 1.35a.635.635 0 00.76.76l1.35-.19a1.189 1.189 0 00.59-.3l3.54-3.54a1.365 1.365 0 000-2.22 1.361 1.361 0 00-2.211.01zM18.7 15.28a3.185 3.185 0 002.22 2.22" />
      </g>
    </svg>
  );
};

const Password = ({ fill, size, height, width, ...props }) => {
  return (
    <svg
      width={size || width || 24}
      height={size || height || 24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill={fill}>
        <path d="M18.75 8v2.1a12.984 12.984 0 00-1.5-.1V8c0-3.15-.89-5.25-5.25-5.25S6.75 4.85 6.75 8v2a12.984 12.984 0 00-1.5.1V8c0-2.9.7-6.75 6.75-6.75S18.75 5.1 18.75 8z" />
        <path d="M18.75 10.1a12.984 12.984 0 00-1.5-.1H6.75a12.984 12.984 0 00-1.5.1C2.7 10.41 2 11.66 2 15v2c0 4 1 5 5 5h10c4 0 5-1 5-5v-2c0-3.34-.7-4.59-3.25-4.9zM8.71 16.71A1.052 1.052 0 018 17a1 1 0 01-.38-.08 1.032 1.032 0 01-.33-.21A1.052 1.052 0 017 16a1 1 0 01.08-.38 1.155 1.155 0 01.21-.33 1.032 1.032 0 01.33-.21 1 1 0 011.09.21 1.155 1.155 0 01.21.33A1 1 0 019 16a1.052 1.052 0 01-.29.71zm4.21-.33a1.155 1.155 0 01-.21.33A1.052 1.052 0 0112 17a1.033 1.033 0 01-.71-.29 1.155 1.155 0 01-.21-.33A1 1 0 0111 16a1.033 1.033 0 01.29-.71 1.047 1.047 0 011.42 0A1.033 1.033 0 0113 16a1 1 0 01-.08.38zm3.79.33a1.014 1.014 0 01-1.42 0 1.014 1.014 0 010-1.42 1.047 1.047 0 011.42 0c.04.05.08.1.12.16a.556.556 0 01.09.17.636.636 0 01.06.18 1.5 1.5 0 01.02.2 1.052 1.052 0 01-.29.71z" />
      </g>
    </svg>
  );
};
